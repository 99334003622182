@use '@angular/material' as mat;

@include mat.core();

@import '@angular/material/theming';
@import "./themes/default-theme";
@import "./themes/light-theme";
@import "./themes/black-theme";
@import "./themes/nature-theme";
@import "./query-theme-variables/theme-variables-holder/theme-variables-holder.component";

@mixin custom-components-theme($theme) {
  // $theme: mat-light-theme($primary-palette, $accent-palette, $warn-palette);
  @include devum-app-theme-emitter($theme);
  // all controls, and any custom style mixins to go here.
  // @include devum-notifications-component-theme($theme);
  $p: map-get($theme, primary);
  $a: map-get($theme, accent);
  $w: map-get($theme, warn);
  $primary: mat-color($p);
  $accent: mat-color($a);
  $warn: mat-color($w);
  $primary-contrast: mat-color($p, default-contrast);
  $accent-contrast: mat-color($a, default-contrast);
  $warn-contrast: mat-color($w, default-contrast);

  .color.primary1 {
    color: $primary;
  }

  .color.accent1 {
    color: $accent;
  }

  .color.warn1 {
    color: $warn;
  }

  .primary1 {
    color: $primary;
  }

  .accent1 {
    color: $accent;
  }

  .warn1 {
    color: $warn;
  }

  .background.primary {
    background-color: $primary;
    color: $primary-contrast;
  }

  .background.accent {
    background-color: $accent;
    color: $accent-contrast;
  }

  .background.warn {
    background-color: $warn;
    color: $warn-contrast;
  }
}

$green: mat-palette($mat-green, A400);
$blue: mat-palette($mat-light-blue, A400);

.default-theme {
  @include mat.all-component-themes($devum-theme);
  @include custom-components-theme($devum-theme);
  // $default-theme: mat-light-theme($green, $blue);
  // @include style-theme($default-theme);
  // @include angular-material-theme($devum-theme);
}

.light-theme {
  @include mat.all-component-themes($devum-light-theme);
  @include custom-components-theme($devum-light-theme);
  // @include angular-material-theme($devum-light-theme);
}

.black-theme {
  @include mat.all-component-themes($devum-black-theme);
  @include custom-components-theme($devum-black-theme);
  // @include custom-components-theme($devum-black-theme);
}

.nature-theme {
  @include mat.all-component-themes($devum-nature-theme);
  @include custom-components-theme($devum-nature-theme);
  // @include custom-components-theme($devum-nature-theme);
}

// example control style
//  @mixin devum-notifications-component-theme($theme) {

//  }

//class NotiFicationsControl
