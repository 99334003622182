.mat-mdc-menu-item {
    font-size: 14px !important;
    height: 35px !important;
    line-height: 35px !important;
    min-height: 35px !important;
    text-decoration: none !important;
    padding: 0 12px !important;
    color: rgba(0, 0, 0, .87);
}

mat-form-field input {
    width: 100%;
    border: 0;
}

.material-icons {
    font-size: 22px !important;
}

.module-menu-wrapper .mat-mdc-select-arrow {
    display: none;
}

input[type="checkbox"] {
    width: 16px !important;
    height: 16px !important;
}

.cdk-table .cdk-header-row .cdk-header-cell:first-child,
.cdk-table .cdk-row .cdk-cell:first-child {
    width: 60px;
    padding-left: 15px;
}

.table-without-checkbox-wrapper .cdk-header-row .cdk-header-cell:first-child,
.table-without-checkbox-wrapper .cdk-row .cdk-cell:first-child {
    padding-left: 15px;
    width: auto;
}

.content-wrapper .table-without-checkbox-wrapper .cdk-header-row .cdk-header-cell:first-child .mat-sort-header-container {
    display: block;
}

.cdk-table .cdk-header-row .cdk-header-cell:first-child .mat-sort-header-container,
.filter-wrapper .mat-mdc-select .mat-mdc-select-arrow-wrapper,
.cdk-header-row .cdk-header-cell:not(:first-child) .resetall-btn,
button.mat-mdc-menu-item[hidden] {
    display: none;
}

.grid-wrapper .mat-mdc-card {
    width: 100% !important;
    padding: 0;
    box-shadow: none !important;
}

.filter-wrapper .filter_icon {
    position: absolute;
    right: 0;
    top: 2px;
}

.filter-wrapper input[type='number'] {
    -moz-appearance: textfield;
}

.filter-wrapper input[type=number]::-webkit-inner-spin-button,
.filter-wrapper input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.filter-wrapper .mat-mdc-text-field-wrapper {
    padding-bottom: 0
}

.filter-wrapper .mat-mdc-form-field-underline {
    bottom: 5px
}

.cdk-table .action-icons .material-icons {
    cursor: pointer;
    font-size: 17px !important;
    line-height: 20px;
    width: 22px;
    height: 22px
}

.cdk-table .action-icons {
    width: 96px !important;
    padding-right: 0 !important;
}

.cdk-overlay-container .mat-mdc-option {
    font-size: 14px;
}

.pagination-wrapper .mat-mdc-mini-fab {
    width: 26px;
    height: 26px;
    line-height: 26px;
    font-size: 13px;
    margin-right: 2px;
    box-shadow: none !important;
}

.pagination-wrapper .mat-mdc-mini-fab .mat-button-wrapper,
.module-menu-wrapper .mat-mdc-form-field-infix {
    padding: 0;
}

mat-icon {
    cursor: pointer;
}

.pagination-wrapper .mat-mdc-icon-button {
    width: 26px !important;
    height: 26px !important;
    line-height: 26px !important;
}


.module-menu-wrapper .mat-mdc-select-value {
    max-width: none;
    color: #fff;
    font-size: 18px;
}

.module-menu-wrapper .mat-mdc-form-field-underline {
    display: none
}

.mat-mdc-form-field-infix,
mat-form-field {
    width: 100% !important;
}

.cdk-overlay-container {
    z-index: 99999 !important;
}

.filter-wrapper .mat-chip:not(.mat-basic-chip) {
    padding: 2px 5px;
    margin: 3px 8px 0 0 !important;
}

.loggedin-section .mat-mdc-menu-content {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    width: 200px;
}

.filter-wrapper .mat-mdc-form-field-can-float .mat-mdc-form-field-autofill-control:focus+.mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label,
.filter-wrapper .mat-mdc-form-field-can-float.mat-mdc-form-field-should-float .mat-mdc-form-field-label {
    transform: perspective(100px) !important;
}

.filter-wrapper .mat-mdc-form-field-can-float .mat-mdc-form-field-autofill-control:focus+.mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label mat-label,
.filter-wrapper .mat-mdc-form-field-can-float.mat-mdc-form-field-should-float .mat-mdc-form-field-label {
    display: none;
}

.filter-wrapper .mat-chip-list {
    float: left;
    width: 90%;
}

.filter-wrapper .mat-chip-list .mat-chip {
    word-break: break-word;
}

.filter-wrapper .mat-mdc-form-field-prefix .mat-mdc-icon-button .mat-button-focus-overlay,
.mat-button .mat-button-focus-overlay {
    background-color: transparent;
}

.filter-wrapper .mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label {
    width: 80% !important;
}

.filter-wrapper .mat-mdc-form-field-infix .mat-mdc-input-element {
    padding-right: 30px;
}

.mat-mdc-tab .mdc-tab__text-label {
    letter-spacing: 0.3px;
}

.mat-accordion .mat-expansion-panel {
    box-sizing: border-box;
}

.mat-accordion .mat-expansion-panel-header {
    height: 45px !important;
    padding: 0 15px;
}

.mat-accordion .mat-expansion-panel-body {
    padding: 0;
}

.mat-accordion span.mat-expansion-indicator {
    height: 45px !important;
}

.mat-accordion .mat-expanded span.mat-expansion-indicator {
    height: 15px !important;
}

.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-prefix .mat-datepicker-toggle-default-icon,
.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-suffix .mat-datepicker-toggle-default-icon {
    width: 16px !important;
}


.mat-mdc-button,
.mat-mdc-raised-button {
    font-size: 13px !important;
    padding: 0 15px !important;
    min-width: 30px !important;
    text-transform: uppercase !important;
}

/* override material button height to 30px except pagebuilder button */
/* pagebuilder button height should take from style config it should not be fixed height=30px */
:not(rt-button)>.mat-mdc-button,
:not(rt-button)>.mat-mdc-raised-button {
    height: 30px !important;
}

.mat-mdc-raised-button[hidden],
.mat-mdc-button-touch-target {
    display: none;
}

.mat-mdc-button mat-icon {
    margin-right: 0 !important
}

button>.mat-icon{
    height: 24px !important;
    width: 24px !important;
    margin: 0 !important;
}

.cdk-cell,
.mat-footer-cell {
    font-size: 12px !important;
}

.cdk-table {
    border-collapse: collapse !important;
    background: inherit;
}

.cdk-table tr.mat-footer-row,
.cdk-table tr.cdk-row {
    height: 40px;
}

.mat-expansion-panel .mat-expansion-panel-content {
    font-size: 13px;
    font-weight: 400;
}

.left-nav-icon img {
    margin-right: 16px;
    vertical-align: middle;
}

.tab-wrapper .mat-mdc-tab {
    height: 45px;
    padding: 0 10px;
    min-width: 110px;
}

.mat-mdc-tab-label-container .mat-mdc-tab {
    min-width: auto
}

.mat-expansion-indicator::after {
    padding: 4px !important;
}

.cdk-table tbody,
.cdk-table tfoot,
.cdk-table thead,
.cdk-table-sticky,
[cdk-footer-row],
[cdk-header-row],
[cdk-row] {
    background: inherit;
}

mat-error {
    font-size: 10px !important;
}

.mat-mdc-form-field-bottom-align::before {
    display: none !important;
}

.mat-option-override {
    line-height: initial !important;
    height: auto !important;
    padding: 10px 16px !important;
}

.mat-option-override .mdc-list-item__primary-text,
.mat-mdc-select-override .mat-mdc-select-value-text,
.mat-mdc-select .mat-mdc-select-value-text {
    white-space: normal;
}

.filter-wrapper rt-multi-select .mat-chip-list[role="listbox"] .mat-chip-list-wrapper {
    max-height: 150px;
    overflow: scroll;
}

.filter-wrapper .search-filter-select .mat-mdc-select .mat-mdc-select-arrow-wrapper {
    display: block;
}

mat-option.mat-mdc-option {
    line-height: initial !important;
    /* height: 36px !important; */
    min-height: auto !important;
    padding: 10px 14px !important;
}

/* .mat-mdc-option .mdc-list-item__primary-text,
.mat-mdc-select .mat-mdc-select-value-text {
    white-space: normal;
} */

.mat-mdc-option .mdc-list-item__primary-text {
    word-break: break-all;
}

input:focus-visible {
    outline: none !important;
}

/* .s-radio .mdc-radio__background,
.s-radio .mdc-radio__outer-circlee,
.s-radio .mdc-radio__inner-circle {
    height: 20px !important;
    width: 20px !important;
}

.s-radio .mat-mdc-radio-button .mat-radio-ripple,
.s-radio .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control,
.s-radio .mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
    height: 30px !important;
    width: 30px !important;
    left: calc(50% - 15px) !important;
    top: calc(50% - 15px) !important;
}

.s-radio .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control {
    right: calc(50% - 15px) !important;
}

.s-radio .mat-mdc-radio-button .mdc-radio {
    padding: 5px !important;
} */

.mat-mdc-paginator-page-size-select {
    width: 56px !important;
}

.mat-mdc-paginator-container {
    justify-content: space-between !important;
}

mat-paginator .mat-mdc-paginator-range-label {
    margin: 0 2px 0 5px;
}

mat-paginator .mat-mdc-paginator-container {
    padding: 0px 4px;
    font-size: 11px;
}

mat-paginator .mat-mdc-paginator-page-size {
    margin-right: 0;
}

mat-paginator .mat-mdc-icon-button {
    width: 30px !important;
    height: 30px !important;
    line-height: 30px !important;
}

.mat-mdc-form-field-appearance-outline .mat-mdc-text-field-wrapper {
    margin: 0 !important;
}

.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-infix {
    border-top: 0.44375em solid transparent;
    padding: 0.7em 0 0.7em 0 !important;
}


/* mat slider style changes with angular upgrade 15 */

.mat-mdc-slider {
    min-width: 76% !important;
    height: 25px !important;
    padding: 0 !important;
    margin: 0px !important;
}


.mat-mdc-slider .mdc-slider__input {
    width: 100% !important;
    left: 0 !important;
}


.mat-mdc-slider .mdc-slider__thumb {
    width: 25px !important;
    height: 25px !important;
    left: -15px !important;
}

/* MatCard style changes with angular upgrade 15 */

mat-card.mdc-card,
div.mdc-card {
    /* display: block !important; */
}

.mat-mdc-card {
    padding: 0 !important;
    border-radius: 0 !important;
    /* background-color: #ffffff !important; */
}


mat-card.mdc-card.rt-flex-row,
div.mdc-card.rt-flex-row {
    display: flex !important;
    flex-direction: row !important;
}

/* mat form field style changes with angular upgrade 15 */

/* mat-form-field .mat-mdc-text-field-wrapper,
input.mat-mdc-input-element {
    background-color: transparent !important;
}



mat-form-field .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
mat-form-field .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
    border-bottom-color: transparent !important
} */

/* mat-icon.mat-icon {
    width: auto !important;
    height: auto !important;
} */

.mat-mdc-button>.mat-icon {
    width: 24px !important;
    height: 24px !important;
}


mat-checkbox .mdc-form-field {
    display: flex;
    align-items: center;
}

mat-checkbox .mdc-checkbox,
mat-radio-button .mdc-radio,
.mat-mdc-icon-button.mat-mdc-button-base {
    padding: 0 !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
    top: 0 !important;
    left: 0 !important;
}

mat-radio-button.mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
    top: calc(-1 * (30px - 20px) / 2) !important;
    left: calc(-1 * (30px - 20px) / 2) !important;
}

mat-radio-button.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control,
mat-radio-button.mat-mdc-radio-button .mdc-radio .mdc-radio__background::before,
mat-radio-button .mat-mdc-radio-touch-target,
mat-checkbox .mat-mdc-checkbox-touch-target {
    width: 30px !important;
    height: 30px !important;
}

.mat-mdc-chip-action-label {
    color: inherit !important;
}


mat-radio-group .mat-mdc-radio-button {
    display: inline-block;
}

/* override mat-form-field styles */


mat-form-field .mat-mdc-text-field-wrapper {
    margin-bottom: 12px !important;
}

.pb-right-wrapper mat-form-field .mat-mdc-text-field-wrapper,
mat-paginator mat-form-field .mat-mdc-text-field-wrapper {
    margin-bottom: 0 !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled),.fluent-middle-wrapper .mdc-text-field--filled.mdc-text-field--disabled{
    background-color: transparent !important;
}

.mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
}

mat-form-field .mat-mdc-form-field-subscript-wrapper {
    display: none;
}

mat-form-field .mat-mdc-form-field-infix {
    padding-top: 14px !important;
    padding-bottom: 8px !important;
    min-height: 40px !important;
}

mat-form-field .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding: 3px !important;
    min-height: 30px !important;
}

mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix .mat-mdc-floating-label {
    top: 22px !important
}

/* .mat-mdc-form-field-type-mat-chip-grid .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix .mat-mdc-floating-label {
    left: 10px !important
} */

mat-form-field.mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
    line-height: inherit !important;
}

mat-select .mat-mdc-select-arrow-wrapper {
    height: auto !important;
}

mat-form-field.mat-form-field-appearance-outline .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
    top: 15px !important;
}

.mdc-text-field {
    padding: 0 !important;
}

mat-tab-group.mat-mdc-tab-group .mat-mdc-tab .mdc-tab__content {
    pointer-events: all;
}

button .mdc-button__label {
    display: flex !important;
    align-items: center !important;
}

.mat-mdc-input-element {
    font-weight: 400;
    font-size: 14px !important;
}

.mat-mdc-form-field-icon-prefix>.mat-icon,
.mat-mdc-form-field-icon-suffix>.mat-icon {
    padding: 0 !important;
}

.mat-mdc-checkbox label,
.mat-mdc-radio-button label {
    font-weight: normal !important;
}

.mat-mdc-icon-button .mat-mdc-button-touch-target {
    display: none;
}

mat-datepicker-toggle button,
mat-datepicker-toggle button svg {
    width: 20px !important;
    height: 20px !important
}

rt-multi-select .mat-mdc-standard-chip .mdc-evolution-chip__cell--primary {
    width: 75% !important;
}

rt-multi-select .mat-mdc-standard-chip .mat-mdc-chip-action-label {
    overflow: hidden !important;
}

rt-multi-select .mat-mdc-standard-chip .mdc-evolution-chip__action--primary {
    padding-left: 5px !important;
    width: 75% !important;
}

rt-multi-select .mat-mdc-standard-chip .mdc-evolution-chip__action {
    align-items: center;
}

.cdk-overlay-pane-override-select{
    width:auto !important;
}