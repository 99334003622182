/* Roboto-regular - latin */


/* latin */

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    src: local("Roboto"), local("Roboto-Regular"), url("fonts/defaultfont/KFOmCnqEu92Fr1Mu4mxK.woff2") format("woff2");
}


/* latin */

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    src: local("Roboto Medium"), local("Roboto-Medium"), url("fonts/defaultfont/KFOlCnqEu92Fr1MmEU9fBBc4.woff2") format("woff2");
}


/* latin */

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    src: local("Roboto Bold"), local("Roboto-Bold"), url("fonts/defaultfont/KFOlCnqEu92Fr1MmWUlfBBc4.woff2") format("woff2");
}

$font-stack: "Roboto",
    sans-serif;
$orange-color: #ff9933;
$red-color: #ff0000;
$green-color: #008000;
$lightgreen-color: #00cc33;
$black-color: #000000;
$seablue-color: #0299d8;
$white-color: #ffffff;
$lightgray-color: #f2f2f2;
$border-color: #ccc;
$purple-color: #7281ce;
$lightred-color: #a9a9a9;
$darkblue-color: #2a3042;
$lightblue-color: #a6b0cf;
$lighterblue-color: #434b64;
$bisque-color: #ffeed3;
$lightpink-color: #fed2d2;
$evergreen-color: #d4fed9;
$gray-color: #333333;
$darkgray-color: #666666;
$thickblue-color: #3F51B5;
$table-border-color: rgba(0,
        0,
        0,
        0.12);

body {
    font: 100% $font-stack;
    overflow: hidden;
}

ol,
ul {
    list-style-type: none !important;
    padding: 0;
    margin: 0;
}

hr {
    background: none repeat scroll 0 0 $border-color;
    border: medium none !important;
    box-shadow: none;
    height: 1px !important;
    width: 100%;
}


/* Generate Display property */

@each $displayclass,
$displayvalue in ("show",
    "block"),
("hidden",
    "none"),
("inline",
    "inline-block"),
("flex",
    "flex"),
("inline-flex",
    "inline-flex") {
    .rt-#{unquote($displayclass)} {
        display: #{unquote($displayvalue)} !important;
    }
}


/* Generate Visible property */

@each $visibleclass,
$visiblevalue in ("invisible",
    "hidden"),
("visible",
    "visible") {
    .rt-#{unquote($visibleclass)} {
        visibility: #{unquote($visiblevalue)} !important;
    }
}


/* Generate Float  property */

@each $floatclass,
$floatvalue in ("pull-left",
    "left"),
("pull-right",
    "right"),
("float-none",
    "none") {
    .rt-#{unquote($floatclass)} {
        float: #{unquote($floatvalue)} !important;
    }
}


/* Generate Text Alignment */

@each $text-transform in lowercase,
uppercase,
capitalize,
inherit {
    .rt-text-#{$text-transform} {
        text-transform: #{$text-transform} !important;
    }
}

@each $text-align in left,
center,
right,
justify {
    .rt-text-#{$text-align} {
        text-align: #{$text-align} !important;
    }
}

.rt-text-orientation-vertical {
    -webkit-writing-mode: vertical-lr;
    -ms-writing-mode: tb-lr;
    writing-mode: vertical-lr;
}

.rt-text-orientation-btm-to-top {
    -webkit-writing-mode: tb-rl;
    -ms-writing-mode: tb-lr;
    writing-mode: tb-rl;
    transform: rotate(-180deg);
}

.rt-text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}


/* Generate Font Size */

@for $i from 9 through 100 {
    .rt-font-#{$i} {
        font-size: #{$i}px !important;
    }
}


/* Generate Top position  */

@for $i from 0 through 15 {
    .rt-top-#{$i} {
        top: #{$i}px !important;
    }
}

@for $i from 0 through 15 {
    .rt-right-#{$i} {
        right: #{$i}px !important;
    }
}


/* Generate Opacity */

@each $color,
$colorcode in ("0",
    0),
("01",
    0.1),
("02",
    0.2),
("03",
    0.3),
("04",
    0.4),
("05",
    0.5),
("06",
    0.6),
("07",
    0.7),
("08",
    0.8),
("09",
    0.9),
("1",
    1) {
    .rt-opacity-#{unquote($color)} {
        opacity: #{$colorcode} !important;
    }
}


/* Generate Font Weight */

@mixin generate-fontweight($small,
    $big,
    $normal) {
    .rt-bold-2x {
        font-weight: $small !important;
    }

    .rt-bold-3x {
        font-weight: $big !important;
    }

    .rt-fontnormal {
        font-weight: $normal !important;
    }
}

@include generate-fontweight(500,
    700,
    normal);

/* Generate Color code */

@each $color,
$colorcode in ("red",
    $red-color),
("green",
    $green-color),
("seablue",
    $seablue-color),
("black",
    $black-color),
("grey",
    $border-color),
("lightgreen",
    $lightgreen-color),
("orange",
    $orange-color),
("lightgray",
    $lightgray-color),
("purple",
    $purple-color),
("white",
    $white-color),
("lightred",
    $lightred-color),
("darkblue",
    $darkblue-color),
("bisque",
    $bisque-color),
("lightpink",
    $lightpink-color),
("evergreen",
    $evergreen-color),
("lightblue",
    $lightblue-color),
("thickgray",
    $gray-color),
("darkgray",
    $darkgray-color),
("blue",
    $thickblue-color) {
    .rt-#{unquote($color)}-color {
        color: #{$colorcode} !important;
    }

    .rt-#{unquote($color)}-bg {
        background: #{$colorcode} !important;
    }
}


/* Generate Borders */

@each $border in left,
top,
right,
bottom {
    .rt-no-#{$border}-border {
        border-#{$border}: none !important;
    }
}

@each $border in left,
top,
right,
bottom {
    .rt-border-#{$border} {
        border-#{$border}: 1px solid rgba(0, 0, 0, 0.12) !important;
    }
}

@each $border in dotted,
dashed {
    .rt-border-#{$border}-lightblue {
        border: 1px #{$border} $lighterblue-color !important;
    }
}

@each $border in left,
top,
right,
bottom {
    .rt-border-#{$border}-lightblue {
        border-#{$border}: 1px solid $lighterblue-color !important;
    }
}

.rt-border-y {
    @extend .rt-pl-0;
    @extend .rt-pr-0;
}

.rt-border-x-lightblue {
    @extend .rt-border-left-lightblue;
    @extend .rt-border-right-lightblue;
}

.rt-border-y-lighblue {
    @extend .rt-border-top-lightblue;
    @extend .rt-border-bottom;
}

.rt-border-lightblue {
    border: 1px solid $lighterblue-color !important;
}

.rt-no-border {
    border: none !important;
}

.rt-border-dashed {
    border: 1px dashed $border-color !important;
}

.rt-border-dotted {
    border: 1px dotted $border-color !important;
}

// .rt-border-left {
//   border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
// }
// .rt-border-right {
//   border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
// }
// .rt-border-top {
//   border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
// }
// .rt-border-bottom {
//   border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
// }
.rt-border-x {
    border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
    border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.rt-border-y {
    border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.rt-border {
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.rt-fieldset {
    border: 2px dashed rgba(0, 0, 0, 0.12) !important;
}


@each $dimension in 15,
16,
17,
18,
19,
20,
21,
22,
23,
24,
25,
30,
35,
40,
45,
50,
55 {
    .rt-dimension-#{$dimension} {
        width: #{$dimension}px !important;
        height: #{$dimension}px !important;
    }
}

@each $width in 20, 30, 40, 50, 60,
70,
80,
90,
100,
115,
125,
130,
140,
150,
180,
200,
250,
300,
350,
400,
420,
450,
500,
550,
600,
650,
700,
750,
800 {
    .rt-width-#{$width} {
        width: #{$width}px !important;
    }
}

@each $height in 20, 30, 40,45, 50, 55, 60, 70, 80,
90,
100,
115,
125,
130,
140,
150,
180,
200,
250,
300,
350,
400,
450,
500,
550,
600,
650,
700,
750,
800 {
    .rt-height-#{$height} {
        height: #{$height}px !important;
    }
}

@each $borderRadius in 1,
2,
3,
4,
5,
6,
7,
8,
9,
10,
15,
20,
25,
30,
35,
40,
45,
50 {
    .rt-border-radius-#{$borderRadius} {
        border-radius: #{$borderRadius}px !important;
    }
}


/* Generate Rotate class */

@each $deg,
$degvalue in ("45deg",
    45),
("50deg",
    50),
("90deg",
    90),
("180deg",
    180),
("270deg",
    270),
("360deg",
    360) {
    .rt-rotate-#{$degvalue} {
        transform: rotate(#{unquote($deg)});
    }
}


/* Generate Cursor class */

@each $cursor in pointer,
default,
not-allowed,
move,
help {
    .rt-cursor-#{$cursor} {
        cursor: $cursor !important;
    }
}


/* Generate Padding and Margin */

@mixin generate-paddingmargin($property,
    $classname) {
    @for $i from 0 through 8 {
        $value: $i * 5;

        #{unquote($classname)}#{$value} {
            #{unquote($property)}: $i * 5px !important;
        }
    }
}

@each $property,
$classname in ("padding-left",
    ".rt-pl-"),
("padding-right",
    ".rt-pr-"),
("padding-top",
    ".rt-pt-"),
("padding-bottom",
    ".rt-pb-"),
("padding",
    ".rt-p-"),
("margin-left",
    ".rt-ml-"),
("margin-right",
    ".rt-mr-"),
("margin-top",
    ".rt-mt-"),
("margin-bottom",
    ".rt-mb-"),
("margin",
    ".rt-m-") {
    @include generate-paddingmargin($property,
        $classname);
}

.rt-px-0 {
    @extend .rt-pl-0;
    @extend .rt-pr-0;
}

.rt-py-0 {
    @extend .rt-pt-0;
    @extend .rt-pb-0;
}

.rt-mx-0 {
    @extend .rt-ml-0;
    @extend .rt-mr-0;
}

.rt-my-0 {
    @extend .rt-mt-0;
    @extend .rt-mb-0;
}

.rt-px-5 {
    @extend .rt-pl-5;
    @extend .rt-pr-5;
}

.rt-py-5 {
    @extend .rt-pt-5;
    @extend .rt-pb-5;
}

.rt-mx-5 {
    @extend .rt-ml-5;
    @extend .rt-mr-5;
}

.rt-my-5 {
    @extend .rt-mt-5;
    @extend .rt-mb-5;
}

.rt-px-10 {
    @extend .rt-pl-10;
    @extend .rt-pr-10;
}

.rt-py-10 {
    @extend .rt-pt-10;
    @extend .rt-pb-10;
}

.rt-mx-10 {
    @extend .rt-ml-10;
    @extend .rt-mr-10;
}

.rt-my-10 {
    @extend .rt-mt-10;
    @extend .rt-mb-10;
}

.rt-px-15 {
    @extend .rt-pl-15;
    @extend .rt-pr-15;
}

.rt-py-15 {
    @extend .rt-pt-15;
    @extend .rt-pb-15;
}

.rt-mx-15 {
    @extend .rt-ml-15;
    @extend .rt-mr-15;
}

.rt-my-15 {
    @extend .rt-mt-15;
    @extend .rt-mb-15;
}

.rt-px-20 {
    @extend .rt-pl-20;
    @extend .rt-pr-20;
}

.rt-py-20 {
    @extend .rt-pt-20;
    @extend .rt-pb-20;
}

.rt-px-25 {
    @extend .rt-pl-25;
    @extend .rt-pr-25;
}

.rt-py-25 {
    @extend .rt-pt-25;
    @extend .rt-pb-25;
}

.rt-px-30 {
    @extend .rt-pl-30;
    @extend .rt-pr-30;
}

.rt-py-30 {
    @extend .rt-pt-30;
    @extend .rt-pb-30;
}

.rt-px-35 {
    @extend .rt-pl-35;
    @extend .rt-pr-35;
}

.rt-py-35 {
    @extend .rt-pt-35;
    @extend .rt-pb-35;
}

.rt-mx-20 {
    @extend .rt-ml-20;
    @extend .rt-mr-20;
}

.rt-my-20 {
    @extend .rt-mt-20;
    @extend .rt-mb-20;
}

.rt-mx-25 {
    @extend .rt-ml-25;
    @extend .rt-mr-25;
}

.rt-my-25 {
    @extend .rt-mt-25;
    @extend .rt-mb-25;
}

.rt-mx-30 {
    @extend .rt-ml-30;
    @extend .rt-mr-30;
}

.rt-my-30 {
    @extend .rt-mt-30;
    @extend .rt-mb-30;
}

.rt-mx-35 {
    @extend .rt-ml-35;
    @extend .rt-mr-35;
}

.rt-my-35 {
    @extend .rt-mt-35;
    @extend .rt-mb-35;
}

.rt-no-outline {
    outline: none !important;
}

.rt-box-shadow-none {
    box-shadow: none !important;
}

.rt-box-shadow {
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.rt-border-radius-none {
    border-radius: 0 !important;
}

.rt-word-wrap {
    word-wrap: break-word;
}

.rt-word-break-all {
    word-break: break-all;
}

.rt-word-break {
    word-break: break-word;
}

.rt-white-space-normal {
    white-space: normal !important;
}

.rt-img-circle {
    border-radius: 50%;
}

.rt-full-width {
    width: 100% !important;
}

.rt-full-height {
    height: 100% !important;
}

.rt-height-auto {
    height: auto !important;
}

.rt-pointer-events-all {
    pointer-events: all;
}

.rt-pointer-events-none {
    pointer-events: none;
}

.rt-maincontainer {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: auto;
    margin-left: auto;
}

.rt-font-italic {
    font-style: italic !important;
}

.rt-clear {
    clear: both !important;
}

.rt-valign-middle {
    vertical-align: middle !important;
}

.rt-valign-top {
    vertical-align: top !important;
}

.rt-valign-bottom {
    vertical-align: bottom !important;
}

.rt-relative {
    position: relative;
}

.rt-absolute {
    position: absolute !important;
}

.rt-fixed {
    position: fixed !important;
}

.rt-overflow-hdn {
    overflow: hidden !important;
}

.rt-text-decoration-none {
    text-decoration: none !important;
}

.rt-text-decoration-underline {
    text-decoration: underline !important;
}

.z-index-99999 {
    z-index: 99999 !important;
}

.rt-overflow-scroll {
    overflow: scroll !important;
}

.rt-overflow-auto {
    overflow: auto !important;
}

.rt-flex-column,
.rt-flex-row {
    display: -webkit-flex !important;
    display: flex !important;
}

.rt-flex-row {
    -webkit-flex-direction: row;
    flex-direction: row !important;
}

.rt-flex-column {
    -webkit-flex-direction: column;
    flex-direction: column !important;
}

.rt-flex-row-reverse {
    -webkit-flex-direction: row-reverse;
    flex-direction: row-reverse !important;
}

.rt-flex-column-reverse {
    -webkit-flex-direction: column-reverse;
    flex-direction: column-reverse !important;
}

.rt-flex-row>.rt-flex {
    min-width: 0;
}

.rt-flex-wrap {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}

.rt-flex-nowrap {
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.rt-flex-items {
    -webkit-box-flex: 1 !important;
    -webkit-flex: 1 !important;
    flex: 1 1 0% !important;
}

.rt-flex-items-auto {
    -webkit-box-flex: 0 !important;
    -webkit-flex: 0 1 auto !important;
    flex: 0 1 auto  !important;
}

.rt-items-start {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
}

.rt-items-end {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    -ms-grid-row-align: flex-end;
    align-items: flex-end;
}

.rt-items-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
}

.rt-items-baseline {
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    -ms-grid-row-align: baseline;
    align-items: baseline;
}

.rt-items-stretch {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    -ms-grid-row-align: stretch;
    align-items: stretch;
}

.rt-self-start {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
}

.rt-self-end {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
}

.rt-self-center {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
}

.rt-self-baseline {
    -webkit-align-self: baseline;
    -ms-flex-item-align: baseline;
    align-self: baseline;
}

.rt-self-stretch {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
}

.rt-justify-start {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start !important;
}

.rt-justify-end {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end !important;
}

.rt-justify-center {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.rt-justify-between {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.rt-justify-around {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

.rt-content-start {
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
}

.rt-content-end {
    -webkit-align-content: flex-end;
    -ms-flex-line-pack: end;
    align-content: flex-end;
}

.rt-content-center {
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
}

.rt-content-between {
    -webkit-align-content: space-between;
    -ms-flex-line-pack: justify;
    align-content: space-between;
}

.rt-content-around {
    -webkit-align-content: space-around;
    -ms-flex-line-pack: distribute;
    align-content: space-around;
}

.rt-content-stretch {
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
}

.rt-flex-center {
    @extend .rt-flex-row;
    @extend .rt-justify-center;
    @extend .rt-items-center;
}


/*backdrop panel class for GRID loader*/

.backdrop-panel {
    pointer-events: none;
    opacity: 0.4;
}

.rt-disable {
    pointer-events: none;
    opacity: 0.3;
}

.rt-enable {
    pointer-events: all;
    opacity: 1;
}

.rt-form-disable {
    pointer-events: none !important;
    opacity: 0.8;
    background-color: #fafafa;
}

// .fb-arrow {
//     @extend .rt-border;
// }

.rt-bg-transparent {
    background: transparent;
}

.d-flex {
    display: flex;
}

// .pb-right-wrapper .mdc-form-field,
// .pb-left-wrapper .mdc-form-field{
//     @extend .rt-lightblue-color;
// }